<template>
  <div class="regis bg-white p-3 pt-5">
    <h3 class="fw-bold">Welcome to PPOB</h3>
    <small>Daftar Sebelum Masuk</small>
    <img src="../assets/3d.jpg" width="100%">
    <form @submit.prevent="registerUser">
      <div class="mb-3">
        <label for="nama" class="form-label">Nama Anda</label>
        <input type="text" class="form-control" id="nama" v-model="form.name">
      </div>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Email Anda</label>
        <input type="email" class="form-control" id="exampleInputEmail1" v-model="form.email">
      </div>
      <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input type="text" class="form-control" id="username" v-model="form.username">
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input type="password" class="form-control" id="password" v-model="form.password">
      </div>
      <button type="submit" class="btn w-100 btn-lg btn-primary">Submit</button>
    </form>
    <p class="mt-3 mb-5">Sudah Punya akun? <router-link to="/">Login</router-link></p>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'HomeView',
  data() {
    return {
      form: {
        name: '',
        email: '',
        username: '',
        password: '',
      },
    };
  },
  methods: {
    async registerUser() {
      try {
        await axios.post('https://api.tokoku.org/public/api/register', this.form);
        
        // Menampilkan SweetAlert2 saat registrasi berhasil
        Swal.fire({
          title: 'Registration Successful!',
          text: 'Your account has been created.',
          icon: 'success',
          confirmButtonText: 'Go to Login',
        }).then(() => {
          // Arahkan pengguna ke halaman login
          this.$router.push('/');
        });
      } catch (error) {
        if (error.response) {
          // Menampilkan error dengan SweetAlert2
          Swal.fire({
            title: 'Error!',
            text: JSON.stringify(error.response.data),
            icon: 'error',
            confirmButtonText: 'Try Again',
          });
        } else {
          Swal.fire({
            title: 'Oops...',
            text: 'Something went wrong. Please try again.',
            icon: 'error',
            confirmButtonText: 'Okay',
          });
        }
      }
    },
  },
};
</script>

<style>
  .regis {
    width: 100%;
    height: 100dvh;
  }
</style>
