<template>
  <div class="profil position-relative">
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="/" class="text-white" style="text-shadow: 0 0 4px rgba(0,0,0, .3);text-decoration: none;"><i class="bi bi-arrow-left-circle-fill me-2"></i>Kembali</router-link>
      </div>
    </div>
    <div class="p-3 rounded bg-white box-profil shadow-lg d-flex align-items-center flex-column" style="padding-top: 80px !important;">      
        <div class="bg-secondary shadow d-flex justify-content-center align-items-center rounded-circle bg-secondary" style="width: 120px;height: 120px;position: absolute;top: -60px;">
          <i class="bi bi-person-fill text-white" style="font-size: 70px;"></i>
        </div>
        <h1>{{ username }}.</h1>
        <form class="w-100 mt-3">
          <div class="mb-3">
            <label class="form-label">Nama Lengkap</label>
            <input type="text" class="form-control" :value="name" disabled>
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Alamat Email</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" :value="email" disabled>
          </div>
          <button type="button" class="btn w-100 btn-lg btn-danger" @click="logout">Logout</button>
        </form>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  name: 'ProfilView',
  data() {
    return {
      username: '',
      name: '',
      email: '',
    };
  },
  mounted() {
    const token = Cookies.get('auth_token');
    const userId = Cookies.get('user_id');

    if (!token || !userId) {
      this.$router.push("/");
      return;
    }

    axios
      .get(`https://api.tokoku.org/public/api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.username = response.data.username;
        this.name = response.data.name;
        this.email = response.data.email;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        this.$router.push("/");
      });
  },
  methods: {
    logout() {
      const token = Cookies.get('auth_token');

      axios
        .post('https://api.tokoku.org/public/api/logout', {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          // Hapus cookies dan redirect ke halaman login
          Cookies.remove('auth_token');
          Cookies.remove('user_id');
          this.$router.push('/');
        })
        .catch((error) => {
          console.error('Error during logout:', error);
        });
    },
  },
};
</script>

<style>
  .profil {
    background-image: url(../assets/bg.jpg);
    background-size: 110%;
    background-position: center;
    width: 100%;
    height: 180px;
    border-radius: 0 0 15px 15px;
  }

  .box-profil {
    width: 90%;
    position: relative;
    left: 5%;
    top: 8vh;
  }
</style>
