import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisView from '../views/RegisView.vue'
import LoginView from '../views/LoginView.vue'
import ProfilView from '../views/ProfilView.vue'

import PulsaView from '../views/pages/PulsaView.vue'
import DataView from '../views/pages/DataView.vue'
import PLNView from '../views/pages/PLNView.vue'
import TFView from '../views/pages/TFView.vue'
import TopupView from '../views/pages/TopupView.vue'
import DanaView from '../views/pages/DanaView.vue'
import OvoView from '../views/pages/OvoView.vue'
import GopayView from '../views/pages/GopayView.vue'
import SpayView from '../views/pages/SpayView.vue'
import TestView from '../views/pages/TestView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profil',
    name: 'profil',
    component: ProfilView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/regis',
    name: 'regis',
    component: RegisView
  },
  {
    path: '/pulsa',
    name: 'pulsa',
    component: PulsaView
  },
  {
    path: '/data',
    name: 'data',
    component: DataView
  },
  {
    path: '/pln',
    name: 'pln',
    component: PLNView
  },
  {
    path: '/tf',
    name: 'tf',
    component: TFView
  },
  {
    path: '/topup',
    name: 'topup',
    component: TopupView
  },
  {
    path: '/dana',
    name: 'dana',
    component: DanaView
  },
  {
    path: '/ovo',
    name: 'ovo',
    component: OvoView
  },
  {
    path: '/gopay',
    name: 'gopay',
    component: GopayView
  },
  {
    path: '/spay',
    name: 'spay',
    component: SpayView
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
