<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> 
  </nav> -->
  <div class="screen" style="position: relative;">
    <router-view/>
    <div class="footer py-5 px-3 bg-primary text-white">
      <h6>PT Indonesia Akita Skye</h6>
      <a href="https://company.tokoku.org" class="text-white text-decoration-none fs-6 my-3 d-flex">About us</a>
      <span>Contact Us :</span><br>
      <a href="mailto:skye.operator@gmail.com" class="text-white text-decoration-none fs-6 d-flex">skye.operator@gmail.com</a>
      <span>+62 857-7008-5997</span>
      <br>
      <br>
      <span>Alamat :</span><br>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7950454296074!2d106.7826872!3d-6.2906471999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f13bf4da5429%3A0xe8c29d298a2fd6db!2sPalma%20Tower!5e0!3m2!1sid!2sid!4v1733886576126!5m2!1sid!2sid" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <small>TB Simatupang, JL. RA Kartini II-S Kav. 6, RT.6/RW.14, Pd. Pinang, Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12310</small>
    </div>
  </div>
</template>

<style>
.footer {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .screen {
    width: 420px;
    min-height: 100dvh;
    margin: auto;
    border-right: 3px solid gainsboro;
    border-left: 3px solid gainsboro;
  }
}
</style>
