<template>
  <div class="pulsa position-relative">
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="/" class="text-white" style="text-shadow: 0 0 4px rgba(0,0,0, .3);text-decoration: none;"><i class="bi bi-arrow-left-circle-fill me-2"></i>Kembali</router-link>
      </div>
    </div>
  </div>
  <div class="container p-4">
    <h3>Transfer Saldo.</h3>
    <form>
      <div class="mb-3">
        <label for="isi" class="form-label">No Tujuan.</label>
        <input type="tel" class="form-control" id="isi" placeholder="Masukan Nomor Tujuan">
      </div>
      <div class="card mb-3 p-3 border-info bg-info-subtle position-relative">
        <small class="text-info">Sumber Dana</small>
        <h6 class="m-0 p-0">Saldo Cash Saya.</h6>
        <h3 class="m-0 p-0">Rp. 100.000</h3>
        <i class="bi bi-cash-stack position-absolute text-info" style="bottom: -10px;right: 10px;font-size: 64px;"></i>
      </div>
      <div class="mb-3">
        <label for="isi" class="form-label">Nominal Transfer.</label>
        <input type="tel" class="form-control" id="isi" placeholder="Masukan Nominal">
      </div>
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Pesan</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Optional" rows="3"></textarea>
      </div>
      <button type="submit" class="btn btn-primary w-100 btn-lg">Kirim</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'TFView',
}
</script>

<style>
  .pulsa {
    background-image: url(../../assets/bg.jpg);
    background-size: 110%;
    background-position: center;
    width: 100%;
    height: 80px;
    border-radius: 0 0 15px 15px;
  }
</style>
